<template>
  <div>
    <div class="flex flex-row w-full">
      <div class="w-1/2">
        <h1
          class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2"
        >
          <span class="text-grey-medium-2 font-normal"> Companies / </span>
          {{ currentCompany.name }}
        </h1>
      </div>
      <div class="flex w-1/2 justify-end">
        <Button
          text="Open as Company Admin"
          type="primary-white"
          size="medium"
          @click.native="doImpersonate"
          data-testid="impersonate-button"
        />
      </div>
    </div>
    <div class="p-2 bg-white rounded shadow relative">
      <Button
        class="absolute right-2"
        text="Export Data"
        type="primary"
        size="medium"
        :iconLeft="downloadIcon"
        @click.native="exportAllUsers"
      />
      <div class="w-3/5 mb-3">
        <p class="mb-2.5 font-bold font-header text-grey-light text-mdl">
          Manage This Company’s Users
        </p>
        <p class="text-grey-dark-1 text-mdh">
          Add and manage employees of this company, control their access level
          with user roles and deactivate those who are no longer with the
          company.
          <br />
          If you choose to upload users, please make sure that you are using our
          CSV template which can be downloaded below.
        </p>
        <p class="text-mdh text-grey-dark-1 font-bold">Statuses:</p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-grey-medium-2">Not invited</span> = users that have
          not been invited to the platform
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-yellow-medium">Invited</span> = users that have been
          sent emails to join the platform and have not created an account
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-green-2">Active</span> = registered users
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-red">Deactivated</span> = user accounts that have
          been closed by admins
        </p>
        <p class="text-mdh text-grey-dark-1">
          <span class="text-purple">Deleted</span> = user accounts that have
          personal information removed
        </p>
      </div>
      <div>
        <CompaniesUsersTable @onChangeStatusFilter="onChangeStatusFilter"/>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import downloadIcon from "@/assets/img/icons/download.svg";
import CompaniesUsersTable from "../components/CompaniesUsersTable";

export default {
  name: "CompanyUsersView",
  components: { CompaniesUsersTable },
  data: () => ({
    downloadIcon,
    statusFilter: -1,
  }),
  computed: {
    ...mapState({
      currentCompany: (state) => state.companies.currentCompany,
    }),
  },
  methods: {
    ...mapActions({ 
      impersonateAdmin: 'auth/impersonateAdmin',
      exportUsers: "companyResults/exportUsers",
    }),
    doImpersonate() {
      this.impersonateAdmin({ orgId: this.currentCompany.id });
    },
    exportAllUsers() {
      this.exportUsers({organizationId: this.$route.params.companyId, status: this.statusFilter});
    },
    onChangeStatusFilter(value) {
      this.statusFilter = (value !== -1 ? value : null);
    }
  }
};
</script>
